<template>
  <div class="recharge">
    <div class="panel-page">

      <div class="panel-heading">
        <h2>
          充值余额 <span><small class="text-danger">当前余额:0.00元</small></span>
          <span class="pull-right">
            <el-button icon="el-icon-bank-card"
                       size="mini">余额日志</el-button>
          </span>
        </h2>
      </div>

      <div class="alert alert-info-light">
        余额可用于购买付费插件或问答社区付费悬赏
      </div>

      <form action=""
            method="post">
        <input type="hidden"
               name="paytype"
               value="wechat">
        <input type="hidden"
               name="money"
               value="10">
        <h3>充值金额</h3>

        <el-row :gutter="10"
                class="row-recharge row-money">
          <el-col :span="4">
            <label class="active"
                   data-type="fixed"
                   data-value="10">
              ￥10 </label>
          </el-col>

          <el-col :span="4">
            <label class=""
                   data-type="fixed"
                   data-value="20">
              ￥20 </label>
          </el-col>

          <el-col :span="4">
            <label class=""
                   data-type="fixed"
                   data-value="30">
              ￥30 </label>
          </el-col>

          <el-col :span="4">
            <label class=""
                   data-type="fixed"
                   data-value="50">
              ￥50 </label>
          </el-col>

          <el-col :span="4">
            <label class=""
                   data-type="fixed"
                   data-value="100">
              ￥100 </label>
          </el-col>

          <el-col :span="4">
            <label data-type="custom"
                   data-value="">
              其它金额
            </label>
          </el-col>
        </el-row>

        <h3>支付类型</h3>

        <el-row :gutter="10"
                class="row-recharge row-paytype">
          <el-col :span="4">
            <label class="active">
              <img :src="imgUrlWechat"
                   alt="">
            </label>
          </el-col>
          <el-col :span="4">
            <label class="">
              <img :src="imgUrlAlipay"
                   alt="">
            </label>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="4">
            <el-button type="primary">立即充值</el-button>
          </el-col>
        </el-row>

      </form>

    </div>
  </div>
</template>

<script>
import mixinImgUrl from '@/utils/mixin/imgUrl.js'
export default {
  name: 'Recharge',
  mixins: [mixinImgUrl]
}
</script>

<style lang="less" scoped>
.panel-page .panel-heading {
  background: transparent;
  border-bottom: none;
  margin: 0 0 30px 0;
  padding: 0;
}

.panel-page .panel-heading h2 {
  font-size: 25px;
  margin-top: 0;
}

.text-danger {
  color: #e74c3c;
}

.pull-right {
  float: right !important;
}

.alert-info-light {
  background-color: #d9edf7;
  border-color: #c6e4f3;
  color: #3a87ad;
}

.alert {
  border-radius: 3px;
}

.alert {
  padding: 15px;
  margin-bottom: 17px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.panel-page h3 {
  margin-bottom: 15px;
  margin-top: 10px;
  color: #444;
  font-size: 16px;
}

.row-recharge > div {
  margin-bottom: 10px;
}

.row-recharge > div > label {
  width: 95%;
  height: 40px;
  display: block;
  font-size: 14px;
  line-height: 40px;
  color: #999;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #ddd;

  font-weight: 400;
}

.row-recharge > div > label.active {
  border-color: #0d95e8;
  color: #0d95e8;
}

.row-recharge > div > label:hover {
  z-index: 4;
  border-color: #27b0d6;
  color: #27b0d6;
}

.row-paytype img {
  height: 22px;
  margin: 8px;
  vertical-align: inherit;
}
</style>
